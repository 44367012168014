/* eslint-disable no-restricted-imports */
import axios from "axios"
import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import SupplierRangeFilterPanel from "../modules/Reports/SupplierRangeFilterPanel"
import ListsWrapper from "../modules/Reports/ListsWrapper"
import { getSuppliers } from "../modules/Suppliers/_axios/supplierCrud"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import * as snackbarRedux from "../../redux/snackbar/snackbarRedux"
import moment from "moment"
import { getSupplierDetailReports, sendSupplierDetailReport } from "../modules/Reports/_axios/reportsCrud"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import OrderItemsList from "../modules/Reports/SupplierDetailsReports/OrderItemsList"
import { useStyles } from "../modules/Common/_styles/listWrapperStyles"
import { formatDateForApi } from "../modules/Common/momentFunctions"
import { useDispatch } from "react-redux"
import { handleApiError } from "../../redux/snackbar/snackbarHandlers"
import * as xlsx from "xlsx"

function SupplierDetailsReportsPage({ userRole, ownSupplierID, intl, ...props }) {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [reportsData, setReportsData] = useState({
    reports: [],
    isLoading: false,
    notRequested: true
  })
  const [sendReportLoading, setSendReportLoading] = useState(false);
  const [exportFileName, setExportFileName] = useState("")
  const [searchParams, setSearchParams] = useState({})
  const [supplierData, setSupplierData] = useState({
    data: [],
    isLoading: true
  })

  const isUserAdmin = userRole === "Admin" || userRole === "Owner"

  function handleSearchParamsSave(changes) {
    const newParams = { ...searchParams, ...changes }
    setSearchParams(newParams)
  }
  function handleSendReport() {
      setSendReportLoading(true);
      const cancelToken = axios.CancelToken.source()
      const { supplier } = searchParams

      const fileTypeXLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
      const fileName = `${exportFileName}.xlsx`
      if (!reportsData.reports || reportsData.reports.length === 0) {
          setSendReportLoading(false);
          return;
      }


      var exportData = getExportData();
      var ws = xlsx.utils.json_to_sheet(exportData)
      var wb = { Sheets: { data: ws }, SheetNames: ["data"], Workbook: { Views: [{ RTL: true }] } }
      var excelBuffer = xlsx.write(wb, { bookType: "xlsx", type: "array" })
      var data = new Blob([excelBuffer], { type: fileTypeXLSX })

      sendSupplierDetailReport(
          supplier.SupplierID,
          fileName,
          data,
          cancelToken.token
      )
          .then(({ data }) => {
              setSendReportLoading(false);
          })
          .catch(error => {
              setSendReportLoading(false);
              if (error.response.data.Message === "Supplier report email not found") {
                  handleApiError(dispatch, error, intl.formatMessage({
                      id: "API.ERROR.FAILED_TO_GET_SUPPLIER_REPORT_EMAIL"
                  }));
              } else {
                  handleApiError(dispatch, error, intl.formatMessage({
                      id: "API.ERROR.FAILED_TO_SEND_REPORTS_TO_EMAILS"
                  }));
              }
          })

      return () => cancelToken.cancel()
  }

  function fetchReports(cancelToken) {
    const { supplier, from, to } = searchParams

    const params = {
      from: formatDateForApi(from),
      to: formatDateForApi(to),
      supplier: supplier.SupplierID
    }

    props.history.push({
      pathname: "/reports-details-supplier",
      search: "?" + new URLSearchParams(params).toString()
    })

    setReportsData({ ...reportsData, isLoading: true })
    getSupplierDetailReports(
      supplier.SupplierID,
      params.from,
      params.to,
      cancelToken.token
    )
      .then(({ data }) => {
        setReportsData({
          reports: data,
          isLoading: false,
          notRequested: false
        })
      })
      .catch(error => handleApiError(dispatch, error))
  }

  function fetchSuppliers(cancelToken) {
    getSuppliers(cancelToken.token, "active")
        .then(({ data }) => {
          console.log("Supplier data",data)
        setSupplierData({ data: data, isLoading: false })
      })
      .catch(error =>
        handleApiError(
          dispatch,
          error,
          intl.formatMessage({
            id: "API.ERROR.FAILED_TO_GET_SUPPLIERS"
          })
        )
      )
  }

  useEffect(() => {
    const cancelToken = axios.CancelToken.source()
    fetchSuppliers(cancelToken)
    return () => cancelToken.cancel()
  }, [])

  useEffect(() => {
    if (!supplierData.isLoading) {
      const urlParams = new URLSearchParams(window.location.search)

      const from = urlParams.get("from")
        ? new Date(urlParams.get("from"))
        : new Date()
      const to = urlParams.get("to")
        ? new Date(urlParams.get("to"))
        : new Date()
      const supplier = isUserAdmin
          ? urlParams.get("supplier")
              ? supplierData.data.find(
                  supplier => supplier.SupplierID === urlParams.get("supplier")
              )
              : {}
          : supplierData.data[0] || {}

      setSearchParams({
        from,
        to,
        supplier
      })
    }
  }, [supplierData])

  useEffect(() => {
    if (
      searchParams.from &&
      searchParams.supplier &&
      searchParams.supplier.SupplierID
    ) {
      const cancelToken = axios.CancelToken.source()
      fetchReports(cancelToken)

      setExportFileName(
        intl.formatMessage({
          id: "REPORT.EXPORT_FILE_NAME.SUPPLIER_DETAIL"
        }) +
          "_" +
          intl.formatMessage({
            id: "EXPORT_FILE_SUPPLIER"
          }) +
          "_" +
          searchParams.supplier.Name +
          "_" +
          moment(searchParams.from).format("DD-MM-YYYY") +
          "_-_" +
          moment(searchParams.to).format("DD-MM-YYYY")
      )
      return () => cancelToken.cancel()
    }
  }, [searchParams])

  function getExportData() {

    const orderQuantity = reportsData.reports.reduce((sum, i) => sum += i.Items.reduce((s, t) => s + t.OrderQuantity, 0), 0)
    const refundQuantity = reportsData.reports.reduce((sum, i) => sum += i.Items.reduce((s, t) => s + t.RefundQuantity, 0), 0)
    const unitPrice = reportsData.reports.reduce((sum, i) => sum += i.Items.reduce(( s, t) => s + t.UnitPrice, 0), 0)
    const totalQuantity = reportsData.reports.reduce((sum, i) => sum += i.Items.reduce((s, t) => s + t.TotalQuantity, 0), 0)
    const totalCost = reportsData.reports.reduce((sum, i) => sum += i.Items.reduce((s, t) => s + t.TotalCost, 0), 0)
    const totalOptionsCost = reportsData.reports.reduce((sum, i) => sum += i.Items.reduce((s, t) => s + t.TotalOptionCost, 0), 0)

    const formattedReport = []
    reportsData.reports.forEach(report => {
      report.Items.forEach(meal => {
        const formattedMeal = {}
        formattedMeal[
          intl.formatMessage({
            id: "REPORT.LOCATION_NAME"
          })
        ] = report.LocationName
        formattedMeal[
          intl.formatMessage({
            id: "REPORT.ITEM_NAME"
          })
        ] = meal.Name
        formattedMeal[
          intl.formatMessage({
            id: "REPORT.QUANTITY"
          })
        ] = meal.OrderQuantity
        formattedMeal[
          intl.formatMessage({
            id: "REPORT.REFOUND_QUANTITY"
          })
        ] = meal.RefundQuantity
        formattedMeal[
          intl.formatMessage({
            id: "REPORT.NET_QUANTITY"
          })
        ] = meal.TotalQuantity
        formattedMeal[
          intl.formatMessage({
            id: "REPORT.MEAL_COST"
          })
        ] = meal.UnitPrice
        formattedMeal[
          intl.formatMessage({
            id: "REPORT.TOTAL_OPTION_COST"
          })
        ] = meal.TotalOptionCost
        formattedMeal[
          intl.formatMessage({
            id: "REPORT.TOTAL_COST"
          })
        ] = meal.TotalCost
        formattedReport.push(formattedMeal)
      })
    })

    const formattedMeal = {}
    formattedMeal[
        intl.formatMessage({
          id: "REPORT.LOCATION_NAME"
        })
        ] = intl.formatMessage({
      id: "REPORT.TOTAL"
    })
    formattedMeal[
        intl.formatMessage({
          id: "REPORT.QUANTITY"
        })
        ] = orderQuantity
    formattedMeal[
        intl.formatMessage({
          id: "REPORT.REFOUND_QUANTITY"
        })
        ] = refundQuantity
    formattedMeal[
        intl.formatMessage({
          id: "REPORT.NET_QUANTITY"
        })
        ] = totalQuantity
    formattedMeal[
        intl.formatMessage({
          id: "REPORT.MEAL_COST"
        })
        ] = unitPrice
        formattedMeal[
          intl.formatMessage({
            id: "REPORT.TOTAL_OPTION_COST"
          })
        ] = totalOptionsCost
    formattedMeal[
        intl.formatMessage({
          id: "REPORT.TOTAL_COST"
        })
        ] = totalCost
    formattedReport.push(formattedMeal)


    return formattedReport
  }

  const locationSummaryTotalCost = reportsData.reports.reduce((sum, item) => {
    return sum + item.LocationTotalCost;
  }, 0);
  const locationRefundQuantity = reportsData.reports.reduce((sum, item) => {
    return sum + item.LocationRefundQuantity;
  }, 0);
  const locationQuantity = reportsData.reports.reduce((sum, item) => {
    return sum + item.LocationQuantity;
  }, 0);
  const locationNetQuantity = reportsData.reports.reduce((sum, item) => {
    return sum + item.LocationNetQuantity;
  }, 0);
  const locationMealCost = reportsData.reports.reduce((sum, item) => {
    return sum + item.LocationMealCost;
  }, 0);
  return (
    <>
      {
        searchParams.supplier && (
        <SupplierRangeFilterPanel
          onSearchParamsSave={handleSearchParamsSave}
          onSendReport={handleSendReport}
          supplierData={supplierData}
          initialSearchParams={searchParams}
          hideSupplierField={!isUserAdmin}
          sendReportLoading={sendReportLoading}
          isSupplierRole={userRole === "Supplier Admin" || userRole === "Supplier User"}
          sendReport={true}
        />
      )}
      <div className={classes.summaryData}>
        {reportsData && reportsData.reports.length > 0 && (
            <div className={classes.summaryDataItem}>
              {
                intl.formatMessage({
                  id: "TOTAL"
                }) + ': ' + Math.round(locationSummaryTotalCost * 100) / 100
              }
            </div>
        )}
        {reportsData && reportsData.reports.length > 0 && (
            <div  className={classes.summaryDataItem}>{intl.formatMessage({
              id: "REFUND_QUANTITY"
            }) + ': ' + locationRefundQuantity}</div>
        )}
        {reportsData && reportsData.reports.length > 0 && (
            <div  className={classes.summaryDataItem}>
              {intl.formatMessage({
                id: "QUANTITY"
              }) + ': ' + locationQuantity}
            </div>
        )}
        {reportsData && reportsData.reports.length > 0 && (
            <div className={classes.summaryDataItem}>
              {intl.formatMessage({
                id: "REPORT.NET_QUANTITY"
              }) + ': ' + locationNetQuantity}
            </div>
        )}
      </div>

      {
        <ListsWrapper
            reportsData={reportsData}
            contents={reportsData.reports.map(location => (
                <Paper className={classes.paper} key={location.LocationName + "_paper"}>
                  <Typography className={classes.heading}>
                    {location.LocationName}
                  </Typography>
                  <OrderItemsList mealsData={location} searchParams={searchParams} />
                </Paper>
            ))}
            exportData={getExportData()}
            exportFileName={exportFileName}
        />
      }

    </>
  )
}

function mapStateToProps(state) {
  return {
    ownSupplierID: state.auth.user.SupplierID,
    userRole: state.auth.user.Role
  }
}

export default withRouter(
  injectIntl(connect(mapStateToProps, snackbarRedux.actions)(SupplierDetailsReportsPage))
)
